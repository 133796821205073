import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { calculateAmortizationTable, calculatePaymentAmount } from '../../calculator/calculations';
import { ExtraPaymentDetails, LoanState } from './types';

const initialState: LoanState = {
  loanStartMonthInput: new Date().getMonth(),
  loanStartYearInput: new Date().getFullYear(),
  principal: 15000,
  term: 60,
  rate: 2.5,
  calculated: {
    hasCalculated: false,
    loanAmount: 0,
    term: 0,
    rate: 0,
    monthlyPayment: 0,
    totalWithoutPayment: 0,
    originalTotalInterestPaid: 0,
    extraTotalInterestPaid: 0,
    extraPaymentAmount: 0,
    averageExtraPayment: 0,
    termPaymentsSaved: 0,
  },
  amortizationTable: undefined,
  rechartsData: undefined,
  extraPayments: [],
};

export const loanSlice = createSlice({
  name: 'loan',
  initialState,
  reducers: {
    setPrincipal: (state, action: PayloadAction<number>) => {
      state.principal = action.payload;
    },
    setTerm: (state, action: PayloadAction<number>) => {
      state.term = action.payload;
    },
    setRate: (state, action: PayloadAction<number>) => {
      state.rate = action.payload;
    },
    setStartMonthInput: (state, action: PayloadAction<string>) => {
      const month = parseInt(action.payload, 10);
      if (!Number.isNaN(month)) {
        state.loanStartMonthInput = month;
      }
    },
    setStartYearInput: (state, action: PayloadAction<string>) => {
      const year = parseInt(action.payload, 10);
      if (!Number.isNaN(year)) {
        state.loanStartYearInput = year;
      }
    },
    calculateAmortization: (state) => {
      const monthlyPayment = calculatePaymentAmount(state.principal, state.rate, state.term);
      const data = calculateAmortizationTable(
        state.extraPayments,
        state.principal,
        state.rate,
        state.term,
        state.loanStartMonthInput,
        state.loanStartYearInput,
      );
      state.amortizationTable = data.amortizationTable;
      state.rechartsData = data.rechartsData;
      state.calculated.hasCalculated = true;
      state.calculated.loanAmount = state.principal;
      state.calculated.term = state.term;
      state.calculated.rate = state.rate;
      state.calculated.monthlyPayment = monthlyPayment;
      state.calculated.originalTotalInterestPaid = data.originalInterestPaid;
      state.calculated.extraTotalInterestPaid = data.interestPaidWithExtra;
      state.calculated.totalWithoutPayment =
        state.principal + state.calculated.originalTotalInterestPaid;
      state.calculated.extraPaymentAmount = data.extraPaymentTotal;
      state.calculated.averageExtraPayment = data.averageExtraPayment;
      state.calculated.termPaymentsSaved = state.term - data.termAfterExtraPayments;
    },
    insertExtraPayment: (state, action: PayloadAction<ExtraPaymentDetails>) => {
      state.extraPayments.push(action.payload);
    },
    updateExtraPayment: (state, { payload }: PayloadAction<ExtraPaymentDetails>) => {
      const editedPayment = {
        key: payload.key,
        amount: payload.amount,
        date: payload.date,
      };
      const payments = state.extraPayments.map((p) => (p.key !== payload.key ? p : editedPayment));
      return {
        ...state,
        extraPayments: [...payments],
      };
    },
    deleteExtraPayment: (state, action: PayloadAction<string>) => {
      const payments = state.extraPayments.filter((p) => p.key !== action.payload);
      return {
        ...state,
        extraPayments: [...payments],
      };
    },
    deleteAllExtraPayments: (state) => ({
      ...state,
      extraPayments: [],
    }),
  },
});

export const {
  setPrincipal,
  setTerm,
  setRate,
  setStartMonthInput,
  setStartYearInput,
  calculateAmortization,
  insertExtraPayment,
  updateExtraPayment,
  deleteExtraPayment,
  deleteAllExtraPayments,
} = loanSlice.actions;
export const loanReducer = loanSlice.reducer;
