import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { formatOutputData } from '../calculator/calculations';
import Row from './table/Row';
import Cell from './table/Cell';

const AmortizationTable = () => {
  const { amortizationTable } = useSelector(({ loan }: RootState) => loan);

  if (!amortizationTable) {
    return <div>Please fill out loan details for amortization table</div>;
  }

  return (
    <table className="w-full table-fixed border p-2 text-center">
      <thead>
        <Row className="bg-grayscaleBg font-bold text-lg">
          <Cell>Payment Date</Cell>
          <Cell>Payment</Cell>
          <Cell>Principal</Cell>
          <Cell>Interest</Cell>
          <Cell>Balance</Cell>
        </Row>
      </thead>
      <tbody>
        {amortizationTable.map((data) => {
          switch (data.paymentType) {
            case 'origination':
              return (
                <Row key={data.paymentNumber}>
                  <Cell className="py-0.75 px-2">{data.date}</Cell>
                  <Cell className="py-0.75 px-2" />
                  <Cell className="py-0.75 px-2">{`$${formatOutputData(data.principal)}`}</Cell>
                  <Cell className="py-0.75 px-2" />
                  <Cell className="py-0.75 px-2">{`$${formatOutputData(data.balance)}`}</Cell>
                </Row>
              );
            case 'extra':
              return (
                <Row key={data.paymentNumber}>
                  <Cell className="py-0.75 px-2">{data.date}</Cell>
                  <Cell className="py-0.75 px-2" />
                  <Cell className="py-0.75 px-2">{`$${formatOutputData(data.principal)}`}</Cell>
                  <Cell className="py-0.75 px-2" />
                  <Cell className="py-0.75 px-2">{`$${formatOutputData(data.balance)}`}</Cell>
                </Row>
              );
            default:
              return (
                <Row key={data.paymentNumber}>
                  <Cell className="py-0.75 px-2">{data.date}</Cell>
                  <Cell className="py-0.75 px-2">
                    {`$${formatOutputData(data.payment)}`}
                  </Cell>
                  <Cell className="py-0.75 px-2">{`$${formatOutputData(data.principal)}`}</Cell>
                  <Cell className="py-0.75 px-2">
                    {`$${formatOutputData(data.interest)}`}
                  </Cell>
                  <Cell className="py-0.75 px-2">{`$${formatOutputData(data.balance)}`}</Cell>
                </Row>
              );
          }
        })}
      </tbody>
    </table>
  );
};

export default AmortizationTable;
