import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import NumberInput from './NumberInput';
import {
  calculateAmortization, deleteAllExtraPayments,
  setPrincipal,
  setRate,
  setStartMonthInput,
  setStartYearInput,
  setTerm,
} from '../redux/loan/loanSlice';

interface Props {
  isModalOpen: boolean;
  onClose: () => void;
}

const GetStaredResetModal = ({ isModalOpen, onClose }: Props) => {
  const dispatch = useDispatch();
  const { hasCalculated } = useSelector(({ loan }: RootState) => loan.calculated);

  const loan = useSelector((state: RootState) => state.loan);

  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear; i >= currentYear - 30; i -= 1) {
    years.push(i);
  }

  if (!isModalOpen) {
    return null;
  }

  if (hasCalculated) {
    // TODO: make it say something about resetting extra payments
  }

  return (
    <div className="fixed inset-0 z-10 h-full w-full overflow-y-auto bg-gray-600 bg-opacity-50">
      <div className="relative top-20 mx-auto w-3/5 rounded-md border bg-white p-5 shadow-lg">
        <div className="flex items-center">
          <div className="flex-grow text-center text-2xl">
            <h2>Enter your loan details:</h2>
          </div>
          <div className="flex-shrink">
            <button type="button" onClick={() => onClose()}>
              X
            </button>
          </div>
        </div>
        <div>
          <div>
            <label htmlFor="principal">
              Principal:
              <NumberInput
                id="principal"
                placeholder="Principal"
                min={0}
                initialValue={loan.principal}
                action={setPrincipal}
              />
            </label>
          </div>
          <div>
            <label htmlFor="term">
              Term:
              <NumberInput
                id="term"
                placeholder="Term in months"
                min={1}
                initialValue={loan.term}
                action={setTerm}
              />
            </label>
          </div>
          <div>
            <label htmlFor="rate">
              Rate:
              <NumberInput
                id="rate"
                placeholder="Interest Rate"
                min={0}
                step={0.01}
                initialValue={loan.rate}
                action={setRate}
              />
            </label>
          </div>
          <div>
            <label htmlFor="start-date">
              Start Date:
              <select
                id="start-date"
                className="m-0.5 border"
                value={loan.loanStartMonthInput}
                onChange={(e) => dispatch(setStartMonthInput(e.target.value))}
              >
                <option value="0">January</option>
                <option value="1">February</option>
                <option value="2">March</option>
                <option value="3">April</option>
                <option value="4">May</option>
                <option value="5">June</option>
                <option value="6">July</option>
                <option value="7">August</option>
                <option value="8">September</option>
                <option value="9">October</option>
                <option value="10">November</option>
                <option value="11">December</option>
              </select>
              <select
                className="m-0.5 border"
                value={loan.loanStartYearInput}
                onChange={(e) => dispatch(setStartYearInput(e.target.value))}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div>
            <button
              className="rounded border bg-purple-700 p-1 text-white"
              type="button"
              onClick={() => {
                dispatch(deleteAllExtraPayments());
                dispatch(calculateAmortization());
                onClose();
              }}
            >
              Calculate
            </button>
            {hasCalculated ? (
              <div className="text-sm text-gray-500">Recalculating will clear out your previously entered extra payments.</div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStaredResetModal;
