import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { PayloadAction } from '@reduxjs/toolkit';

export interface Props {
  id: string;
  placeholder: string;
  min: number;
  initialValue: number;
  step?: number;
  action: (value: number) => PayloadAction<number>;
}

const NumberInput: React.FC<Props> = ({ id, placeholder, min, initialValue, action, step }) => {
  const dispatch = useDispatch();
  const [internalValue, setInternalValue] = useState<string>(initialValue.toString(10));
  const [formatted, setFormatted] = useState<string>(initialValue.toLocaleString());
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const onChange = (val: string) => {
    if (val === '') {
      setInternalValue('');
      setFormatted('');
      return;
    }

    const num = Number.parseFloat(val);
    if (Number.isNaN(num)) {
      return;
    }

    dispatch(action(num));
    setFormatted(num.toLocaleString());
    setInternalValue(num.toString());
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  return (
    <input
      id={id}
      className="m-0.5 border"
      type={isFocused ? 'number' : 'text'}
      min={min}
      step={step}
      value={isFocused ? internalValue : formatted}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
      onBlur={() => onBlur()}
      onFocus={() => onFocus()}
    />
  );
};

export default NumberInput;
