import React from 'react';
import clsx from 'clsx';

export interface Props {
  children: React.ReactNode;
  className?: string;
}

const Row: React.FC<Props> = ({ children, className }) => (
  <tr className={clsx(className)}>
    {children}
  </tr>
);

export default Row;
