import { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dateOptions, ExtraPaymentDetails } from '../../calculator/calculations';
import {
  calculateAmortization,
  deleteExtraPayment,
  updateExtraPayment,
} from '../../redux/loan/loanSlice';
import { formatDateForInput } from '../../util/DateUtil';

import trash from '../../assets/images/trash.svg';
import checkmark from '../../assets/images/checkmark.svg';
import pencil from '../../assets/images/pencil.svg';

interface Props {
  payment: ExtraPaymentDetails;
}

const ExtraPaymentEditDetails = ({ payment }: Props) => {
  const dispatch = useDispatch();
  const [editState, setEditState] = useState<boolean>(false);

  const { amount, date, key } = payment;
  const inputStartDate = formatDateForInput(new Date(date));

  const [inputDate, setInputDate] = useState<string>(inputStartDate);
  const [underlyingDate, setUnderlyingDate] = useState<number>(new Date(date).getTime());
  const [inputAmount, setInputAmount] = useState<number>(amount);

  const onSave = async () => {
    setEditState(false);
    await dispatch(
      updateExtraPayment({
        key,
        amount: inputAmount,
        date: underlyingDate,
      }),
    );
    await dispatch(calculateAmortization());
  };

  const onDelete = async () => {
    await dispatch(deleteExtraPayment(key));
    await dispatch(calculateAmortization());
  };

  const onDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUnderlyingDate(new Date(e.target.value).getTime());
  };

  if (!editState) {
    return (
      <div className="flex items-center">
        {`${new Date(date).toLocaleDateString(undefined, dateOptions)} - $${amount}`}
        <button className="mx-2" type="button" onClick={() => setEditState(!editState)}>
          <img src={pencil} alt="edit payment" className="h-6" />
        </button>
      </div>
    );
  }

  return (
    <div className="flex items-center">
      <input
        className="border text-black"
        type="date"
        value={inputDate}
        onChange={(e) => {
          setInputDate(e.target.value);
          onDateChange(e);
        }}
      />
      <input
        className="border text-black"
        type="number"
        value={inputAmount}
        onChange={(e) => setInputAmount(e.target.valueAsNumber)}
      />
      <button type="button" className="mx-2" onClick={() => onSave()}>
        <img src={checkmark} alt="confirm" className="h-6" />
      </button>
      <button type="button" className="mx-2" onClick={() => onDelete()}>
        <img src={trash} alt="delete" className="h-6" />
      </button>
    </div>
  );
};

export default ExtraPaymentEditDetails;
