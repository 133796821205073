import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { RootState } from '../redux/store';
import { formatDateForInput } from '../util/DateUtil';
import { calculateAmortization, insertExtraPayment } from '../redux/loan/loanSlice';
import ExtraPaymentsModal from './ExtraPaymentsModal';

const ExtraPayments = () => {
  const dispatch = useDispatch();
  const { term, loanStartYearInput, loanStartMonthInput } = useSelector(
    ({ loan }: RootState) => loan,
  );
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const beginDate = new Date(loanStartYearInput, loanStartMonthInput, 1);
  const begin = formatDateForInput(beginDate);

  const defaultStartDate = new Date(beginDate.getTime());
  defaultStartDate.setMonth(defaultStartDate.getMonth() + 1);
  const defaultStart = formatDateForInput(defaultStartDate);

  const endDate = new Date(beginDate.getTime());
  endDate.setMonth(beginDate.getMonth() + term);

  const end = formatDateForInput(endDate);

  const [amount, setAmount] = useState<string>('0.00');
  const [date, setDate] = useState<string>(defaultStart);

  const addExtraPayment = () => {
    const paymentDate = new Date(date);
    paymentDate.setHours(paymentDate.getHours() + paymentDate.getTimezoneOffset() / 60);
    const amt = parseFloat(amount);
    if (Number.isNaN(amt)) {
      alert('bad number');
      return;
    }
    dispatch(
      insertExtraPayment({
        key: uuid(),
        amount: amt,
        date: paymentDate.getTime(),
      }),
    );
    dispatch(calculateAmortization());
  };

  return (
    <>
      <div>
        <input
          className="m-0.5 border"
          type="date"
          min={begin}
          max={end}
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
      </div>
      <div>
        <input
          className="m-0.5 border"
          type="text"
          placeholder="Extra Principal Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </div>
      <div>
        <button
          className="rounded border bg-purple-700 p-1 text-white"
          type="button"
          onClick={() => addExtraPayment()}
        >
          Add Extra Payment
        </button>
      </div>
      <div>
        <button
          className="rounded border bg-purple-700 p-1 text-white"
          type="button"
          onClick={() => setModalOpen(!modalOpen)}
        >
          Edit Extra Payments
        </button>
      </div>
      <ExtraPaymentsModal isModalOpen={modalOpen} onClose={() => setModalOpen(!modalOpen)} />
    </>
  );
};

export default ExtraPayments;
