import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import ExtraPaymentEditDetails from './ExtraPayments/ExtraPaymentEditDetails';

interface Props {
  isModalOpen: boolean;
  onClose: () => void;
}

const ExtraPaymentsModal = ({ isModalOpen, onClose }: Props) => {
  const { extraPayments } = useSelector(({ loan }: RootState) => loan);

  if (!isModalOpen) {
    return null;
  }

  const listExtraPayments = () => {
    if (extraPayments.length === 0) {
      return <div>Add an extra payment to get started.</div>;
    }

    return extraPayments.map((payment) => (
      <ExtraPaymentEditDetails key={payment.key} payment={payment} />
    ));
  };

  return (
    <div className="fixed inset-0 h-full w-full overflow-y-auto bg-gray-600 bg-opacity-50 z-10">
      <div className="relative top-20 mx-auto w-3/5 rounded-md border bg-white p-5 shadow-lg">
        <div className="flex items-center">
          <div className="flex-grow text-center text-2xl">
            <h2>Modify Your Extra Payments</h2>
          </div>
          <div className="flex-shrink">
            <button type="button" onClick={() => onClose()}>
              X
            </button>
          </div>
        </div>
        <div>{listExtraPayments()}</div>
      </div>
    </div>
  );
};

export default ExtraPaymentsModal;
