import React from 'react';
import clsx from 'clsx';

export interface Props {
  children?: React.ReactNode;
  className?: string;
}

const Cell: React.FC<Props> = ({ children, className }) => (
  <td className={clsx('border p-4', className)}>
    {children}
  </td>
);

export default Cell;
