import { useSelector } from 'react-redux';
import Graph from './components/Graph';
import AmortizationTable from './components/AmortizationTable';
import LoanDetails from './components/LoanDetails';
import { RootState } from './redux/store';
import Landing from './Landing';

const App = () => {
  const { principal } = useSelector(({ loan }: RootState) => loan);

  if (principal === 0) {
    return <Landing />;
  }

  return (
    <div className="min-h-screen bg-slate-100 py-2">
      <div className="mx-12">
        <div className="my-2 text-center text-4xl text-3xl underline">Your Loan Visualization</div>
        <div className="m-2 flex flex-row gap-12">
          <div className="min-w-fit flex-grow max-w-xs">
            <LoanDetails />
          </div>
          <div className="flex-grow">
            <Graph />
            <AmortizationTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
