import { useSelector } from 'react-redux';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { RootState } from '../redux/store';

const Graph = () => {
  const { rechartsData } = useSelector(({ loan }: RootState) => loan);

  if (!rechartsData) {
    return null;
  }

  return (
    <div className="flex h-60 w-full">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={rechartsData}>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <XAxis dataKey="date" />
          <YAxis />
          <Legend />
          <Line
            dot={false}
            type="monotone"
            dataKey="Original Balance"
            stroke="#0000FF"
            strokeDasharray="5 5"
          />
          <Line
            dot={false}
            type="monotone"
            dataKey="Original Interest Paid"
            stroke="#FF0000"
            strokeDasharray="5 5"
          />
          <Line dot={false} type="monotone" dataKey="Balance" stroke="#0000FF" />
          <Line dot={false} type="monotone" dataKey="Interest Paid" stroke="#FF0000" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Graph;
