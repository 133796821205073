export const dateOptions: Intl.DateTimeFormatOptions = {
  month: 'long',
  year: 'numeric',
};
export const twoDigitNumberOptions: Intl.NumberFormatOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};
export const rateNumberOptions: Intl.NumberFormatOptions = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 4,
};

export const formatOutputData = (input: number): string => input
  .toLocaleString(undefined, twoDigitNumberOptions);

export interface AmortizationDetails {
  paymentType: string;
  paymentNumber: string;
  date: string;
  payment: number;
  principal: number;
  interest: number;
  balance: number;
}

export const calculateMonthlyInterestRate = (rate: number): number => rate / 100 / 12;
export const roundToTwoDigits = (input: number): number => Math.round(input * 100) / 100;

export const calculatePaymentAmount = (
  principal: number,
  periodicInterestRate: number,
  term: number,
) => {
  const monthlyInterestRate = calculateMonthlyInterestRate(periodicInterestRate);
  const base = (1 + monthlyInterestRate) ** term;
  return principal * ((monthlyInterestRate * base) / (base - 1));
};

export interface AmortizationOutput {
  amortizationTable: AmortizationDetails[];
  rechartsData: any;
  totalRepayment: number;
  originalRepayment: number;
  originalInterestPaid: number;
  interestPaidWithExtra: number;
  extraPaymentTotal: number;
  averageExtraPayment: number;
  termAfterExtraPayments: number;
}

export interface ExtraPaymentDetails {
  key: string;
  amount: number;
  date: number;
}

const calculateOriginalAmortizationData = (
  payment: number,
  rate: number,
  principal: number,
  startMonth: number,
  startYear: number,
) => {
  let balance = principal;
  let interestPaid = 0;
  let repayment = 0;

  const paymentDate = new Date(startYear, startMonth + 1, 1);
  const rechartsData = [];
  while (balance > 0) {
    const interestPortion = balance * calculateMonthlyInterestRate(rate);
    const principalPortion = payment - roundToTwoDigits(interestPortion);

    let remainingBalance = roundToTwoDigits(balance - principalPortion);

    interestPaid += interestPortion;

    if (Math.round((remainingBalance * 100) / 100) <= 0.0) {
      remainingBalance = 0;
    }

    const date = paymentDate.toLocaleDateString('en-US', dateOptions);

    rechartsData.push({
      date,
      'Original Balance': roundToTwoDigits(remainingBalance),
      'Original Interest Paid': roundToTwoDigits(interestPaid),
    });
    balance = remainingBalance;
    repayment += payment;
    paymentDate.setMonth(paymentDate.getMonth() + 1);
  }

  return {
    originalRechartsData: rechartsData,
    originalRepayment: repayment,
    originalInterestPaid: interestPaid,
  };
};

export const calculateAmortizationTable = (
  extraPayments: ExtraPaymentDetails[],
  principal: number,
  periodicInterestRate: number,
  term: number,
  startMonth: number,
  startYear: number,
): AmortizationOutput => {
  const payment = calculatePaymentAmount(principal, periodicInterestRate, term);
  const { originalRechartsData, originalRepayment, originalInterestPaid } =
    calculateOriginalAmortizationData(
      payment,
      periodicInterestRate,
      principal,
      startMonth,
      startYear,
    );
  const rechartsData: any[] = [];
  const mergedRechartsData: any[] = [];

  const loanOriginationDate = new Date(startYear, startMonth, 1);
  let balance = principal;
  let totalRepayment = 0;
  const paymentDate = new Date(startYear, startMonth + 1, 1);
  let interestPaid = 0;
  const data: AmortizationDetails[] = [];
  let i = 0;
  data.push({
    paymentType: 'origination',
    paymentNumber: `-${i}`,
    date: loanOriginationDate.toLocaleDateString(undefined, dateOptions),
    payment: 0,
    principal: -balance,
    interest: 0,
    balance,
  });
  mergedRechartsData.push({
    date: loanOriginationDate.toLocaleDateString(undefined, dateOptions),
    Balance: roundToTwoDigits(principal),
    'Interest Paid': roundToTwoDigits(0),
    'Original Balance': roundToTwoDigits(principal),
    'Original Interest Paid': roundToTwoDigits(0),
  });

  let extraPaymentTotal = 0;
  let extraPaymentCount = 0;
  for (let extraPaymentI = 0; extraPaymentI < extraPayments.length; extraPaymentI += 1) {
    const extraPayment = extraPayments[extraPaymentI];
    extraPaymentTotal += extraPayment.amount;
    extraPaymentCount += 1;
    if (
      extraPayment.date >= loanOriginationDate.getTime() &&
      extraPayment.date <= paymentDate.getTime()
    ) {
      const extraPaymentDate = new Date(extraPayment.date);
      balance -= extraPayment.amount;
      data.push({
        paymentType: 'extra',
        paymentNumber: `${i}-${extraPaymentI}`,
        date: extraPaymentDate.toLocaleDateString('en-US', dateOptions),
        payment: extraPayment.amount,
        principal: extraPayment.amount,
        interest: 0,
        balance,
      });
    }
  }
  while (balance > 0) {
    const interestPortion = balance * calculateMonthlyInterestRate(periodicInterestRate);
    const principalPortion = payment - roundToTwoDigits(interestPortion);

    let remainingBalance = roundToTwoDigits(balance - principalPortion);

    interestPaid += interestPortion;
    if (remainingBalance <= 0.0) {
      remainingBalance = 0;
    }
    const date = paymentDate.toLocaleDateString(undefined, dateOptions);

    if (remainingBalance + interestPortion < payment) {
      data.push({
        paymentType: 'regular',
        paymentNumber: `${i}`,
        date,
        payment: interestPortion + balance,
        principal: balance,
        interest: interestPortion,
        balance: remainingBalance,
      });
    } else {
      data.push({
        paymentType: 'regular',
        paymentNumber: `${i}`,
        date,
        payment,
        principal: principalPortion,
        interest: interestPortion,
        balance: remainingBalance,
      });
    }

    const beginningOfPeriod = paymentDate;
    const endOfPeriod = new Date(paymentDate.getFullYear(), paymentDate.getMonth() + 1, 0);
    for (let index = 0; index < extraPayments.length; index += 1) {
      const extraPayment = extraPayments[index];
      const extraPaymentDate = new Date(extraPayment.date);
      if (
        extraPaymentDate.getTime() <= endOfPeriod.getTime() &&
        extraPaymentDate.getTime() >= beginningOfPeriod.getTime()
      ) {
        remainingBalance -= extraPayment.amount;
        totalRepayment += extraPayment.amount;
        data.push({
          paymentType: 'extra',
          paymentNumber: `${i}-${index}`,
          date: extraPaymentDate.toLocaleDateString('en-US', dateOptions),
          payment: extraPayment.amount,
          principal: extraPayment.amount,
          interest: 0,
          balance: remainingBalance,
        });
      }
    }
    rechartsData.push({
      date,
      Balance: roundToTwoDigits(remainingBalance),
      'Interest Paid': roundToTwoDigits(interestPaid),
    });
    balance = remainingBalance;
    totalRepayment += payment;
    paymentDate.setMonth(paymentDate.getMonth() + 1);
    i += 1;
  }

  let interestPaidWithExtraPayments = 0;
  for (let j = 0; j < originalRechartsData.length; j += 1) {
    const original = originalRechartsData[j];
    const updated = rechartsData[j];
    if (updated) {
      interestPaidWithExtraPayments = roundToTwoDigits(updated['Interest Paid']);
      mergedRechartsData.push({
        date: original.date,
        Balance: roundToTwoDigits(updated.Balance),
        'Interest Paid': roundToTwoDigits(updated['Interest Paid']),
        'Original Balance': roundToTwoDigits(original['Original Balance']),
        'Original Interest Paid': roundToTwoDigits(original['Original Interest Paid']),
      });
    } else {
      mergedRechartsData.push({
        date: original.date,
        'Original Balance': roundToTwoDigits(original['Original Balance']),
        'Original Interest Paid': roundToTwoDigits(original['Original Interest Paid']),
      });
    }
  }

  const termAfterExtraPayments = data.filter((item) => item.paymentType === 'regular').length;

  const averageExtraPayment = extraPaymentTotal / extraPaymentCount;
  const avgExtraPayment = Number.isNaN(averageExtraPayment) ? 0 : averageExtraPayment;

  return {
    amortizationTable: data,
    rechartsData: mergedRechartsData,
    totalRepayment,
    originalRepayment,
    originalInterestPaid,
    interestPaidWithExtra: interestPaidWithExtraPayments,
    extraPaymentTotal,
    averageExtraPayment: avgExtraPayment,
    termAfterExtraPayments,
  };
};
