import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { rateNumberOptions, twoDigitNumberOptions } from '../calculator/calculations';
import ExtraPayments from './ExtraPayments';
import GetStartedReset from './GetStartedReset';

const LoanDetails = () => {
  const { calculated } = useSelector(({ loan }: RootState) => loan);
  const interestSaved = calculated.originalTotalInterestPaid - calculated.extraTotalInterestPaid;
  const totalPaidWithExtraPayments = calculated.extraTotalInterestPaid + calculated.loanAmount;

  const formatTermOutput = (value: number) => {
    if (value < 12) {
      const monthPlural = value === 1 ? 'month' : 'months';
      return `${value} ${monthPlural}`;
    }
    const years = value / 12;
    const yearPlural = years === 1 ? 'year' : 'years';
    const months = value % 12;
    const monthPlural = months === 1 ? 'month' : 'months';

    if (months === 0) {
      return `${Math.trunc(years)} ${yearPlural}`;
    }
    return `${Math.trunc(years)} ${yearPlural} and ${months} ${monthPlural}`;
  };

  return (
    <>
      <h1 className="text-2xl font-bold">Loan Details</h1>
      <div className="flex">
        <div className="mr-6">Loan Amount:</div>
        <div className="flex-grow text-right">
          {`$${calculated.loanAmount.toLocaleString(undefined, twoDigitNumberOptions)}`}
        </div>
      </div>
      <div className="flex">
        <div className="mr-6">Monthly Payment:</div>
        <div className="flex-grow text-right">
          {`$${calculated.monthlyPayment.toLocaleString(undefined, twoDigitNumberOptions)}`}
        </div>
      </div>
      <div className="flex">
        <div className="mr-6">Term:</div>
        <div className="flex-grow text-right">{formatTermOutput(calculated.term)}</div>
      </div>
      <div className="flex">
        <div className="mr-6">Interest Rate:</div>
        <div className="flex-grow text-right">
          {`${calculated.rate.toLocaleString(undefined, rateNumberOptions)}%`}
        </div>
      </div>
      <div className="m-3 text-center">
        <GetStartedReset />
      </div>

      <h1 className="mt-6 text-2xl font-bold">Payment Details</h1>
      <h2 className="text-sm">(Without Extra Payments)</h2>
      <div className="flex">
        <div className="mr-6">Total Interest Paid:</div>
        <div className="flex-grow text-right">
          {`$${calculated.originalTotalInterestPaid.toLocaleString(
            undefined,
            twoDigitNumberOptions,
          )}`}
        </div>
      </div>
      <div className="flex">
        <div className="mr-6">Total Amount Paid:</div>
        <div className="flex-grow text-right">
          {`$${calculated.totalWithoutPayment.toLocaleString(undefined, twoDigitNumberOptions)}`}
        </div>
      </div>

      <h1 className="mt-6 text-2xl font-bold">Payment Details</h1>
      <h2 className="text-sm">(With Extra Payments)</h2>
      <div className="flex">
        <div className="mr-6">Total Interest Paid:</div>
        <div className="flex-grow text-right">
          {`$${calculated.extraTotalInterestPaid.toLocaleString(undefined, twoDigitNumberOptions)}`}
        </div>
      </div>
      <div className="flex">
        <div className="mr-6">Total Amount Paid:</div>
        <div className="flex-grow text-right">
          {`$${totalPaidWithExtraPayments.toLocaleString(undefined, twoDigitNumberOptions)}`}
        </div>
      </div>
      <div className="flex">
        <div className="mr-6">Time Saved:</div>
        <div className="flex-grow text-right">
          {`${formatTermOutput(calculated.termPaymentsSaved)}`}
        </div>
      </div>
      <div className="flex">
        <div className="mr-6">Extra Payments Total:</div>
        <div className="flex-grow text-right">
          {`$${calculated.extraPaymentAmount.toLocaleString(undefined, twoDigitNumberOptions)}`}
        </div>
      </div>
      <div className="flex">
        <div className="mr-6">Total Interest Saved:</div>
        <div className="flex-grow text-right">
          {`$${interestSaved.toLocaleString(undefined, twoDigitNumberOptions)}`}
        </div>
      </div>
      <div className="flex">
        <div className="mr-6">Average Extra Payment:</div>
        <div className="flex-grow text-right">
          {`$${calculated.averageExtraPayment.toLocaleString(undefined, twoDigitNumberOptions)}`}
        </div>
      </div>

      <div className="flex flex-row">
        <div className="flex-shrink">
          <div>
            <ExtraPayments />
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanDetails;
