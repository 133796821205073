import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { Props } from './table/Cell';
import GetStartedResetModal from './GetStartedResetModal';

const GetStartedReset: React.FC<Props> = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { hasCalculated } = useSelector(({ loan }: RootState) => loan.calculated);

  return (
    <>
      <button
        className={`rounded-lg border ${
          hasCalculated ? 'border-red-500 bg-red-500' : 'border-blue-600 bg-blue-600'
        } px-4 py-3 text-white`}
        type="button"
        onClick={() => setModalOpen(true)}
      >
        {hasCalculated ? 'Start Over?' : 'Get Started!'}
      </button>
      <GetStartedResetModal isModalOpen={modalOpen} onClose={() => setModalOpen(!modalOpen)} />
    </>
  );
};

export default GetStartedReset;
